<template>
	<div class="single--post" v-if="Object.keys(post).length">
		<div class="post--wrapper">
			<div class="post--title">
				<h1 v-html="post[0].title.rendered">{{post[0].title.rendered}}</h1>
			</div>
			<div class="post--image">
				<ImageById :media_id="post[0].featured_media" />
			</div>
			<div class="post--text">
				<span class="post-data">{{ $t('single-post.date-label') + ' ' + $d(new Date(), 'short') }}</span>
				<div class="post--content" v-html="post[0].content.rendered"></div>
			</div>
		</div>
		<div class="other--posts">
			<div class="prev--post" v-if="Object.keys(prev_post).length">
				<div class="item--wrapper">
					<router-link :to="$i18nRoute({ name: 'Post', params: {slug: prev_post.slug} })" class="link--mouse-small" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
						<div class="item--image">
							<ImageById :media_id="prev_post.featured_media" />
						</div>
					</router-link>
					<div class="item--title">
						<h2 v-html="prev_post.title.rendered"></h2>
						<span class="button">
							<router-link :to="$i18nRoute({ name: 'Post', params: {slug: prev_post.slug} })" class="link--mouse-small" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
								{{$t('generale.read-more')}}
							</router-link>
						</span>
					</div>
				</div>
			</div>
			<div class="next--post" v-if="Object.keys(next_post).length">
				<div class="item--wrapper">
					<router-link :to="$i18nRoute({ name: 'Post', params: {slug: next_post.slug} })" class="link--mouse-small" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
						<div class="item--image">
							<ImageById :media_id="next_post.featured_media" />
						</div>
					</router-link>
					<div class="item--title">
						<h2 v-html="next_post.title.rendered"></h2>
						<span class="button">
							<router-link :to="$i18nRoute({ name: 'Post', params: {slug: next_post.slug} })" class="link--mouse-small" @mouseenter.native="changeMouse('on')" @mouseleave.native="changeMouse('off')">
								{{$t('generale.read-more')}}
							</router-link>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import ImageById from '@/components/ImageById';
export default {
	data() {
		return {
			slug: this.$route.params.slug,
			current_language : this.$route.params.locale,
			post_id: 0,
			post: [],
			next_post: [],
			prev_post: [],
			headTitle: ''
		}
	},
	components: {
		ImageById
	},
	metaInfo() {
		return{
			title: this.headTitle,
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {
		getIDFromSlug() {
			axios
				.post(process.env.VUE_APP_API_URL + '/wl/v1/getPostBySlug/', {"slug": this.slug, "lang": this.current_language})
				.then(res => {
					this.post_id = res.data;
					this.getPrevNextPost()
					axios
						.get(process.env.VUE_APP_API_URL + '/wp/v2/posts/?include=' + this.post_id + '&lang=' + this.current_language)
						.then(res => {
							this.post = res.data;
							this.headTitle = this.post[0].title.rendered
						});
				});
		},
		async getPrevNextPost() {
			let res = await axios.get(process.env.VUE_APP_API_URL + '/wp/v2/posts/' + '?lang=' + this.current_language);
			Object.entries(res.data).map((el, index) => {
				if(el[1].id == this.post_id){
					if(index == 0) {
						this.prev_post = res.data[res.data.length-1]
					}else{
						this.prev_post = res.data[index-1]
					}

					if(index == (res.data.length - 1)) {
						this.next_post = res.data[0]
					}else{
						this.next_post = res.data[index+1]
					}
				}
			})
		}
	},
	beforeMount(){
		this.getIDFromSlug()	
	},
	watch: {
		'$route.params.slug': function(){
			this.slug = this.$route.params.slug
			this.current_language = this.$route.params.locale
			this.getIDFromSlug();
		},
		'$route.params.locale': function(){
			this.slug = this.$route.params.slug
			this.current_language = this.$route.params.locale
			this.getIDFromSlug();
		}
	}
}
</script>

<style lang="scss">
.single--post {
	position: relative;
	padding: 15vh 1vw 0 1vw;
	@include mobile {
		padding: calc(60px + 5vw) 5vw 0 5vw;
	}
	.post--wrapper {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-bottom: 20vh;
		@include mobile {
			padding-bottom: 10vh;
		}
		.post--title {
			position: relative;
			width: 60vw;
			text-align: center;
			@include mobile {
				width: 100%;
			}
			h1 {
				text-transform: uppercase;
				font-family: 'Saol Display';
				font-size: 4vw;
				line-height: 4vw;
				font-weight: 400;
				color: $black;
				margin: 0;
				@include mobile {
					font-size: 8vw;
					line-height: 8vw;
				}
			}
		}
		.post--image {
			position: relative;
			width: 40vw;
			margin-top: 1vw;
			@include mobile {
				width: 100%;
				margin-top: 5vw;
			}
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
		.post--text {
			position: relative;
			width: 40vw;
			margin-top: 5vh;
			@include mobile {
				width: 100%;
				margin-top: 5vw;
			}
			.post-data {
				font-family: 'Suisse Int';
				font-weight: 400;
				color: $black;
				font-size: 12px;
				line-height: 12px;
				text-transform: uppercase;
				margin-bottom: 3vh;
				display: block;
			}
			.post--content {
				position: relative;
				width: 100%;
				height: auto;
				p {
					color: $black;
					font-size: 24px;
					font-weight: 400;
					font-family: 'Suisse Int';
					margin: 0 0 5vh 0;
					&:last-of-type {
						margin: 0;
					}
					@include mobile {
						font-size: 16px;
						line-height: 20px;
					}
				}
			}
		}
	}
	.other--posts {
		position: relative;
		width: 100%;
		border-top: 1px solid $black;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		@include mobile {
			flex-direction: column;
		}
		.prev--post {
			position: relative;
			width: 50%;
			border-right: 1px solid $black;
			box-sizing: border-box;
			padding: 1vw;
			@include mobile {
				padding: 5vw 0;
				width: 100%;
				border-right: 0;
				border-bottom: 1px solid $black;
			}
			.item--wrapper {
				position: relative;
				width: 100%;
				height: auto;
				a {
					position: relative;
					width: 100%;
					height: auto;
					display: block;
					.item--image {
						position: relative;
						width: 100%;
						height: 30vw;
						@include mobile {
							height: auto;
						}
						img {
							width: 100%;
							height: 100%;
							display: block;
							object-fit: cover;
						}
					}
				}
				.item--title {
					position: relative;
					width: 100%;
					padding: 5vh 0;
					text-align: center;
					@include mobile {
						padding: 5vw 0 0 0;
						text-align: left;
					}
					h2 {
						text-transform: uppercase;
						font-family: 'Saol Display';
						font-size: 2.5vw;
						line-height: 2.5vw;
						font-weight: 400;
						color: $black;
						margin: 0;
						@include mobile {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 5vw;
							line-height: 5vw;
							text-transform: uppercase;
						}
					}
					.button {
						position: relative;
						width: auto;
						background-color: transparent;
						border-radius: 50px;
						text-align: center;
						border: 1px solid $black;
						display: inline-block;
						margin-top: 5vh;
						@include mobile {
							margin-top: 5vw;
						}
						a {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 12px;
							line-height: 12px;
							text-transform: uppercase;
							padding: 15px 30px;
							box-sizing: border-box;
						}
					}
				}
			}
		}
		.next--post {
			position: relative;
			width: 50%;
			box-sizing: border-box;
			padding: 1vw;
			@include mobile {
				padding: 5vw 0;
				width: 100%;
				border-right: 0;
			}
			.item--wrapper {
				position: relative;
				width: 100%;
				height: auto;
				a {
					position: relative;
					width: 100%;
					height: auto;
					display: block;
					.item--image {
						position: relative;
						width: 100%;
						height: 30vw;
						@include mobile {
							height: auto;
						}
						img {
							width: 100%;
							height: 100%;
							display: block;
							object-fit: cover;
						}
					}
				}
			}
			.item--title {
					position: relative;
					width: 100%;
					padding: 5vh 0;
					text-align: center;
					@include mobile {
						padding: 5vw 0;
						text-align: left;
					}
					h2 {
						text-transform: uppercase;
						font-family: 'Saol Display';
						font-size: 2.5vw;
						line-height: 2.5vw;
						font-weight: 400;
						color: $black;
						margin: 0;
						@include mobile {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 5vw;
							line-height: 5vw;
							text-transform: uppercase;
						}
					}
					.button {
						position: relative;
						width: auto;
						background-color: transparent;
						border-radius: 50px;
						text-align: center;
						border: 1px solid $black;
						display: inline-block;
						margin-top: 5vh;
						@include mobile {
							margin-top: 5vw;
						}
						a {
							font-family: 'Suisse Int';
							font-weight: 400;
							color: $black;
							font-size: 12px;
							line-height: 12px;
							text-transform: uppercase;
							padding: 15px 30px;
							box-sizing: border-box;
						}
					}
				}
		}
	}
}
</style>