<template>
	<div class="single--product">
		<ProductsHeader :isFiltersShowed="false" :isQuantityUpdating="isQuantityUpdating" />
		<InfoProduct :open="isInfoOpen" :product="product" @infoClosing="openInfo" v-if="Object.keys(fields).length" />
		<div class="product--container" v-if="Object.keys(fields).length">
			<div class="product--slider">
				<CarouselProduct :gallery="fields.galleria"/>
			</div>
			<div class="product--details">
				<DetailsProduct @infoOpening="openInfo" @updatePrdQnt="updatePrdQnt" :product="product" :fields="fields" />
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import CarouselProduct from '@/components/CarouselProduct';
import DetailsProduct from '@/components/DetailsProduct';
import ProductsHeader from '@/components/ProductsHeader';
import InfoProduct from '@/components/InfoProduct';
export default {
  data() {
		return {
			slug: this.$route.params.slug,
			current_language : this.$route.params.locale,
			product: [],
			fields: {},
			isInfoOpen: false,
			isQuantityUpdating: 0,
			headTitle: ''
		}
	},
	metaInfo() {
		return{
			title: this.headTitle,
			titleTemplate: '%s | Wearlight',
		}
  },
	components: {
		CarouselProduct,
		DetailsProduct,
		InfoProduct,
		ProductsHeader
	},
	methods: {
		openInfo(val){
			if(val){
				this.isInfoOpen = true
			}else{
				this.isInfoOpen = false
			}
    },
		updatePrdQnt(val) {
			this.isQuantityUpdating = val
		},
		getProductData() {
			axios
				.get(process.env.VUE_APP_API_URL + '/wp/v2/prodotto/?slug=' + this.slug)
				.then(response => {
					this.product = response.data;
					this.headTitle = this.product[0].title.rendered
					let dt = {
						'id': response.data[0].id,
						'lang': this.current_language
					}
					axios
						.post(process.env.VUE_APP_API_URL + '/wl/v1/acf/getprodfieldbyid/', dt)
						.then(response => {
							this.fields = response.data
						})
				});
		}
	},
	beforeMount(){
		this.getProductData()
	},
	watch: {
		'$route.params.slug': function(){
			this.slug = this.$route.params.slug
			this.getProductData();
		},
		'$route.params.locale': function(){
			this.current_language = this.$route.params.locale
			this.getProductData();
		}
	}
}
</script>

<style lang="scss">
.single--product {
	position: relative;
	padding: 120px 1vw 0 1vw;
	@include mobile {
		padding: 100px 5vw 0 5vw;
	}
	.product--container {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		min-height: calc(100vh - 80px);
		box-sizing: border-box;
		@include mobile {
			flex-direction: column;
		}
		.product--slider {
			position: relative;
			width: 50%;
			box-sizing: border-box;
			border-right: 1px solid $black;
			@include mobile {
				width: 100%;
				border-right: none;
			}
		}
		.product--details {
			position: relative;
			width: 50%;
			padding: 1vw 0 1vw 1vw;
			box-sizing: border-box;
			@include mobile {
				width: 100%;
				padding: 5vh 0;
			}
		}
	}
}
</style>