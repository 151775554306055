<template>
  <div class="product--carousel">
		<div class="product--carousel-nav">
			<div class="nav--prev" @click.prevent="prevSlide">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
				<polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "/>
				</svg>
			</div>
			<div class="nav--next" @click.prevent="nextSlide">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
				<polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106 "/>
				</svg>
			</div>
		</div>
    <div class="product--image-full">
      <div class="full--item" v-for="(image, index) in gallery" :key="index" :order="index+1">
				<div class="full--item-inner">
					<img :src="image">
				</div>
      </div>
    </div>
    <div class="product--image-thumbs">
      <div @click.prevent="changeSlide($event)" class="thumbs--item" v-for="(image, index) in gallery" :key="index" :order="index+1">
				<svg viewBox="0 0 100 100" class="activeBorder">
					<line class="top" x1="0" y1="0" x2="100" y2="0" />
					<line class="right" x1="100" y1="0" x2="100" y2="100" />
					<line class="bottom" x1="100" y1="100" x2="0" y2="100" />
					<line class="left" x1="0" y1="100" x2="0" y2="0" />
				</svg>
        <img :src="image">
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
export default {
  data() {
    return {
      current: 1,
			total: this.gallery.length,
			isMoving: false
    }
  },
  props: {
		gallery: {
			type: Array,
			required: true,
		},
  },
	methods: {
		prevSlide() {
			if(!this.isMoving){
				let next;
				if(this.current == 1){
					next = this.total;
				}else{
					next = this.current - 1;
				}
				this.animateThumbs(this.current, next)
				this.moveSlider(this.current, next)
				this.current = next;
			}
		},
		nextSlide() {
			if(!this.isMoving){
				let next;
				if(this.current != this.total){
					next = this.current + 1;
				}else{
					next = 1;
				}
				this.animateThumbs(this.current, next)
				this.moveSlider(this.current, next)
				this.current = next;
			}
		},
		changeSlide(event){
			let next = parseInt(event.currentTarget.getAttribute('order'));
			if(next != this.current && !this.isMoving){
				this.animateThumbs(this.current, next)
				this.moveSlider(this.current, next)
				this.current = next;
			}
		},
		getLineLength(){
			let svgs = document.querySelectorAll('.activeBorder');
			[...svgs].forEach((svg) => {
				let lines = svg.querySelectorAll('line');
				[...lines].forEach((line) => {
					let len = line.getTotalLength();
					line.style.strokeDasharray = len + ' ' + len;
					if(svg.closest('.thumbs--item').getAttribute('order') == this.current){
						line.style.strokeDashoffset = 0;
					}else{
						line.style.strokeDashoffset = len;
					}
				})
			});
		},
		moveSlider(prev, next){
			this.isMoving = true;
			let prev_el = document.querySelector('.product--image-full .full--item[order="' + prev + '"]');
			let prev_el_in = prev_el.querySelector('.full--item-inner');
			let next_el = document.querySelector('.product--image-full .full--item[order="' + next + '"]');
			let next_el_in = next_el.querySelector('.full--item-inner');

			gsap.to(prev_el, {duration: 1.4, x: '-100%', ease: 'power2.out', onComplete: () => {
				gsap.to(prev_el, {duration: 0, x: '100%'})
				this.isMoving = false;
			}})
			gsap.to(prev_el_in, {duration: 1.4, scale: 0.6, autoAlpha: 0, ease: 'power2.out'})
			gsap.to(next_el, {duration: 1.4, x: '0', ease: 'power2.out'})
			gsap.to(next_el_in, {duration: 1.4, scale: 1, autoAlpha: 1, ease: 'power2.out'})
		},
		animateThumbs(current, next){
			let old = document.querySelector('.thumbs--item[order="' + current + '"] .activeBorder');
			let newSl = document.querySelector('.thumbs--item[order="' + next + '"] .activeBorder');
			
			gsap.to(old.querySelectorAll('line'), { duration: 0.35, stagger: -0.2, strokeDashoffset: 100, ease: 'power2.in' })
			gsap.to(newSl.querySelectorAll('line'), { duration: 0.35, stagger: 0.2, strokeDashoffset: 0, ease: 'power2.out' })
		},
		resetSlides() {
			let els = document.querySelectorAll('.full--item');
			[...els].forEach((el) => {
				gsap.to(el, {duration: 0, x: '100%'})
				gsap.to(el.querySelector('.full--item-inner'), {duration: 0, scale: 0.6, autoAlpha: 0})
			});
			let cur = document.querySelector('.full--item[order="' + this.current + '"]')
			gsap.to(cur, {duration: 0, x: 0})
			gsap.to(cur.querySelector('.full--item-inner'), {duration: 0, scale: 1, autoAlpha: 1})
		}
	},
	mounted(){
		this.getLineLength();
	},
	updated(){
		this.current = 1
		this.getLineLength();
		this.resetSlides();
	},
	watch: {
		gallery: {
      handler() {
        this.current = 1
      },
      deep: true
    },
		'$route.params.slug': function(){
			this.current = 1
		}
	}
}
</script>

<style lang="scss">
.product--carousel {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
	overflow: hidden;
	.product--carousel-nav {
		z-index: 6;
		.nav--prev {
			position: absolute;
			top: 50%;
			left: 0;
			width: 40px;
			transform: translateY(-50%);
			svg {
				width: 100%;
				fill: $black;
				transform: rotate(180deg);
			}
			@include mobile {
				width: 20px
			}
		}
		.nav--next {
			position: absolute;
			top: 50%;
			right: 1vw;
			width: 40px;
			transform: translateY(-50%);
			svg {
				width: 100%;
				fill: $black;
			}
			@include mobile {
				width: 20px
			}
		}
	}
  .product--image-full {
    position: relative;
    width: 100%;
    box-sizing: border-box;
		z-index: 5;
    .full--item {
      position: absolute;
			top: 0;
			left: 5vw;
      width: calc(100% - 10vw);
      height: auto;
			transform: translateX(100%);
			.full--item-inner {
				position: relative;
				transform: scale(0.6);
				opacity: 0;
				img {
					width: 100%;
					height: auto;
					display: block;
				}
			}
			&[order="1"]{
				position: relative;
				top: auto;
				transform: translateX(0);
				.full--item-inner {
					transform: scale(1);
					opacity: 1;
				}
			}
    }
  }
  .product--image-thumbs {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
		padding-top: 1vw;
		padding-right: 1vw;
		box-sizing: border-box;
    .thumbs--item {
      position: relative;
      width: 100%;
      height: auto;
			margin: 0 0.5vw;
			box-sizing: border-box;
			z-index: 4;
			.activeBorder {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 5;
				line {
					stroke: rgba($black, 1);
				}
			}
      img {
        width: 100%;
        height: auto;
        display: block;
      }
			&:first-of-type {
				margin-left: 0;
			}
			&:last-of-type {
				margin-right: 0;
			}
			&.active {
				.activeBorder {
					line {
						stroke-dashoffset: 0;
					}
				}
			}
    }
  }
}
</style>