<template>
  <div class="details--list">
		<div class="wrp">
			<div class="detail" v-if="product[0].title.rendered != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.nome')}}</span>
				</div>
				<div class="data">
					<span>{{product[0].title.rendered}}</span>
				</div>
			</div>
			<div class="detail" v-if="Object.keys(product[0].serie).length">
				<div class="title">
					<span>{{$t('prodotto.dettagli.serie')}}</span>
				</div>
				<div class="data">
					<span>{{seriesName.join(' - ')}}</span>
				</div>
			</div>
			<div class="detail" v-if="fields.sviluppo_serie != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.sviluppo')}}</span>
				</div>
				<div class="data">
					<span>{{fields.sviluppo_serie}}</span>
				</div>
			</div>
			<div class="detail" v-if="productCnt != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.tipologia')}}</span>
				</div>
				<div class="data">
					<span>{{strippedContent(productCnt)}}</span>
				</div>
			</div>
			<div class="detail" v-if="fields.tipologia_compound != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.tip_compound')}}</span>
				</div>
				<div class="data">
					<span>{{fields.tipologia_compound}}</span>
				</div>
			</div>
			<div class="detail" v-if="fields.codici_forme_sviluppate_su_prototipo != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.codici')}}</span>
				</div>
				<div class="data">
					<span>{{fields.codici_forme_sviluppate_su_prototipo}}</span>
				</div>
			</div>
			<div class="detail" v-show="showRel">
				<div class="title">
					<span>{{$t('prodotto.dettagli.correlati')}}</span>
				</div>
				<div class="data withLink">
					<div class="link" v-for="(rel, index) in fields.altri_articoli_stessa_dima"  :key="index">
						<router-link class="link--mouse-small" :to="'/product/' + rel.post_name">{{rel.post_title}}</router-link>
						<span class="sep" v-if="Object.keys(fields.altri_articoli_stessa_dima).length > 1 && index != (Object.keys(fields.altri_articoli_stessa_dima).length - 1)">-</span>
					</div>
				</div>
			</div>
			<div class="detail" v-if="fields.lunghezza != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.lunghezza')}}</span>
				</div>
				<div class="data">
					<span>{{fields.lunghezza}}</span>
				</div>
			</div>
			<div class="detail" v-if="fields.altezza_tacco != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.altezza_tacco')}}</span>
				</div>
				<div class="data">
					<span>{{fields.altezza_tacco}}</span>
				</div>
			</div>
			<div class="detail" v-if="fields.altezza_pianta != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.altezza_pianta')}}</span>
				</div>
				<div class="data">
					<span>{{fields.altezza_pianta}}</span>
				</div>
			</div>
			<div class="detail" v-if="fields.profondita_cassetta != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.profondita')}}</span>
				</div>
				<div class="data">
					<span>{{fields.profondita_cassetta}}</span>
				</div>
			</div>
			<div class="detail">
				<div class="title">
					<span>{{$t('prodotto.dettagli.canalino')}}</span>
				</div>
				<div class="data">
					<span>{{fields.canalino_di_cucitura ? $t('prodotto.dettagli.si') : $t('prodotto.dettagli.no')}}</span>
				</div>
			</div>
			<div class="detail">
				<div class="title">
					<span>{{$t('prodotto.dettagli.brevetto')}}</span>
				</div>
				<div class="data">
					<span>{{fields.brevetto_europeo ? $t('prodotto.dettagli.si') : $t('prodotto.dettagli.no')}}</span>
				</div>
			</div>
			<div class="detail" v-if="fields.altre_mescole != ''">
				<div class="title">
					<span>{{$t('prodotto.dettagli.altre_mescole')}}</span>
				</div>
				<div class="data">
					<span>{{fields.altre_mescole}}</span>
				</div>
			</div>
		</div>		
		<div class="product--buttons">
			<div class="button link--mouse-small" @click.prevent="openInfo">
				<span>{{$t('prodotto.info')}}</span>
			</div>
			<div @click.prevent="addToCart" class="button link--mouse-small" :class="{ 'disabled' : isProductOnCart }">
				<span v-if="!isProductOnCart">{{$t('prodotto.invio_campione_1')}}</span>
				<span v-else>{{$t('prodotto.invio_campione_2')}}</span>
			</div>
		</div>
		<div class="product-on-cart" v-if="isProductOnCart">
			<span>{{$t('prodotto.cart_text')}}</span>
			<span @click.prevent="removeFromCart" class="lnk" @mouseenter="changeMouse('on')" @mouseleave="changeMouse('off')">{{$t('prodotto.cart_remove')}}</span>
		</div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      seriesName: [],
			isProductOnCart: false,
			showRel: false,
			productCnt: '',
			current_language : this.$route.params.locale,
    }
  },
  props: {
    product: {
			type: Array,
			required: true,
		},
    fields: {
			type: Object,
			required: true,
		},
  },
	watch: {
		'$route.params.locale': function(){
			this.current_language = this.$route.params.locale
			this.productContent();
		}
	},
  methods: {
		productContent(){
			axios
			.get(process.env.VUE_APP_API_URL + '/wp/v2/prodotto/?include=' + this.product[0].id + '&lang=' + this.current_language)
			.then(response => {
				if(response.data.length == 0){
					axios
					.get(process.env.VUE_APP_API_URL + '/wp/v2/prodotto/?include=' + this.product[0].id)
					.then(response => {
						this.productCnt = response.data[0].content.rendered
					});
				}else{
					this.productCnt = response.data[0].content.rendered
				}
				
			});
		},
		checkFld() {
			if(typeof this.fields.altri_articoli_stessa_dima === 'object' && this.fields.altri_articoli_stessa_dima !== null){
				this.showRel = true
			}else{
				this.showRel = false
			}
		},
		removeFromCart() {
			let data = {
				'user': this.currentUserID,
				'product': this.product[0].id
			}
			axios
				.post(process.env.VUE_APP_API_URL + '/wl/v1/cart/remove/', data)
				.then(response => {
					if(response.data.success){
						this.isProductOnCart = false
						this.$emit('updatePrdQnt', 2)
					}
				});
		},
		checkProductOnCart() {
			let data = {
				'user': this.currentUserID,
				'product': this.product[0].id
			}
			axios
				.post(process.env.VUE_APP_API_URL + '/wl/v1/cart/check/', data)
				.then(response => {
					if(response.data.success){
						this.isProductOnCart = true
					}else{
						this.isProductOnCart = false
					}
				});
		},
		addToCart() {
			this.$formLoaderVisible = true
			let data = {
				'user': this.currentUserID,
				'product': this.product[0].id
			}
			axios
				.post(process.env.VUE_APP_API_URL + '/wl/v1/cart/add/', data)
				.then(response => {
					this.$formLoaderVisible = false
					if(response.data.success){
						this.$emit('updatePrdQnt', 1)
						this.isProductOnCart = true
					}else{
						//this.isProductOnCart = false
					}
				});
		},
		openInfo() {
			this.$emit('infoOpening', true)
		},
    getSeries(ids) {
      ids = Object.keys(ids).map(key => ids[key]).join(",");
      axios
        .get(process.env.VUE_APP_API_URL + '/wp/v2/serie?include=' + ids)
        .then(response => {
          for (const [key, value] of Object.entries(response.data)){
            key
            this.seriesName.push(value.name)
          }
        });
    },
    strippedContent(str) {
      let regex = /(<([^>]+)>)/ig;
      return str.replace(regex, "");
    }
  },
	beforeMount(){
		this.checkProductOnCart()
		this.checkFld()
		this.productContent()
	},
  mounted(){
    //console.log(this.product)
    this.getSeries(this.product[0].serie)
    let linkSmall = document.querySelectorAll('.link--mouse-small');
		[...linkSmall].forEach(el => {
			if(!el.classList.contains('disabled')){
				el.addEventListener('mouseenter', () => {
					let cursor = document.getElementById('cursor')
					cursor.classList.add('onLink--small')
				})
				el.addEventListener('mouseleave', () => {
					let cursor = document.getElementById('cursor')
					cursor.classList.remove('onLink--small')
				})
			}
		});
  }
}
</script>

<style lang="scss">
.details--list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  .wrp {
		.detail {
			position: relative;
			width: 100%;
			border-top: 1px solid $black;
			padding: 1.5vh 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			&:first-of-type {
				border-top: 0;
			}
			&:last-of-type {
				border-bottom: 1px solid $black;
			}
			.title {
				width: 50%;
				span {
					font-family: 'Suisse Int';
					font-weight: 700;
					color: $black;
					font-size: 12px;
					line-height: 12px;
					text-transform: uppercase;
				}
			}
			.data {
				text-align: right;
				width: 50%;
				span {
					font-family: 'Suisse Int';
					font-weight: 500;
					color: $black;
					font-size: 12px;
					line-height: 12px;
					text-transform: uppercase;
				}
				&.withLink {
					display: flex;
					flex-wrap: nowrap;
					.link {
						a {
							text-decoration: none;
							font-family: 'Suisse Int';
							font-weight: 500;
							color: $black;
							font-size: 12px;
							line-height: 12px;
							text-transform: uppercase;
						}
						.sep {
							margin: 0 5px;
							text-decoration: none;
							font-family: 'Suisse Int';
							font-weight: 500;
							color: $black;
							font-size: 12px;
							line-height: 12px;
							text-transform: uppercase;
						}
					}
				}
			}
		}
  }
	.product--buttons {
		position: relative;
		width: 100%;
		height: auto;
		padding: 1.5vh 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		@include mobile {
			padding: 5vh 0;
			flex-direction: column;
		}
		.button {
			position: relative;
			width: calc(50% - 1vw);
			background-color: $black;
			border-radius: 50px;
			padding: 15px 0;
			text-align: center;
			@include mobile {
				width: 100%;
				&:last-of-type {
					margin-top: 3vh;
				}
			}
			span {
				font-family: 'Suisse Int';
				font-weight: 400;
				color: $white;
				font-size: 12px;
				line-height: 12px;
				text-transform: uppercase;
			}
			&.disabled {
				background-color: rgba($black, 0.4);
			}
		}
	}
	.product-on-cart{
		position: relative;
		width: 100%;
		height: auto;
		padding: 10px 1vw;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		box-sizing: border-box;
		background-color: rgba($black, 0.4);
		span {
			font-family: 'Suisse Int';
			font-weight: 400;
			color: $white;
			font-size: 14px;
			line-height: 14px;
			&.lnk {
				text-transform: uppercase;
			}
		}
		@include mobile {
			padding: 10px;
		}
	}
}
</style>